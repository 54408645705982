import React from "react";
import { FaEllipsisV } from "react-icons/fa";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector,
} from "recharts";

const datas = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const NewChart = () => {
  return (
    <div className="px-[25px]  pb-[40px]">
      <div className="flex mt-[22px] w-full gap-[30px] ">
        <div className="cursor-pointer rounded-[4px] ">
          <div className="flex items-center justify-between py-[15px] px-[20px]  mb-[20px] ">
           
            <FaEllipsisV color="gray" className="cursor-pointer" />
          </div>

          <div className="w-full">
            {/* <canvas id="myAreaChart"></canvas> */}
            {/* <Line options={options} data={data} /> */}
            <LineChart
              width={1150}
              height={500}
              data={datas}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#3874FF"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="uv" stroke="#60C6FF" />
            </LineChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewChart;
